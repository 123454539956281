export class ProductDB {
  public static products = [
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Italy Resident",
        "email": "italy.resident@romemail.com",
        "memberOf": "11/15/2023 09:45",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Germany Renter",
        "email": "germany.renter@berlinmail.com",
        "memberOf": "09/30/2023 14:20",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "France Occupant",
        "email": "france.occupant@parismail.com",
        "memberOf": "10/25/2023 08:10",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "USA Lessee",
        "email": "usa.lessee@nyemail.com",
        "memberOf": "11/10/2023 17:35",
        "permissions": "LOCAL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Canada Tenant",
        "email": "canada.tenant@torontomail.com",
        "memberOf": "10/18/2023 12:15",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "UK Dweller",
        "email": "uk.dweller@londonmail.com",
        "memberOf": "11/03/2023 16:50",
        "permissions": "LOCAL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Australia Resident",
        "email": "australia.resident@sydneymail.com",
        "memberOf": "09/28/2023 10:30",
        "permissions": "LOCAL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Brazil Occupant",
        "email": "brazil.occupant@riomail.com",
        "memberOf": "10/08/2023 14:40",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Mexico Lessee",
        "email": "mexico.lessee@mexicocitymail.com",
        "memberOf": "11/20/2023 11:25",
        "permissions": "ALL"
    },
    {
        "_id": "5a9ae2106518248b68251fdf",
        "photo": "./assets/images/products/default-user.jpg",
        "username": "Japan Tenant",
        "email": "japan.tenant@tokyomail.com",
        "memberOf": "10/13/2023 09:15",
        "permissions": "ALL"
    }
];
    // public static products = [
    //   {
    //     '_id': '5a9ae2106518248b68251fdf',
    //     'name': 'Wireless Bluetooth V4.0 Portable Speaker with HD Sound and Bass',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'Lorem ipsum dolor sit amet, et nec putent quodsi, admodum assentior ad duo. Pri ad sapientem ocurreret incorrupte',
    //     'category': 'speaker',
    //     'tags': [
    //       'sunt',
    //       'sunt',
    //       'culpa'
    //     ],
    //     'price': {
    //       'sale': 32,
    //       'previous': 54
    //     },
    //     'ratings': {
    //       'rating': 3.86,
    //       'ratingCount': 26
    //     },
    //     'features': [
    //       'aliquip aliquip',
    //       'nulla laboris',
    //       'pariatur consequat'
    //     ],
    //     'photo': './assets/images/products/speaker-1.jpg',
    //     'gallery': [
    //       './assets/images/products/speaker-1.jpg',
    //       './assets/images/products/speaker-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '20% off',
    //       'color': 'info'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210b7b4d3ad2f048bbe',
    //     'name': 'Portable Speaker with HD Sound',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'cillum eiusmod',
    //     'category': 'speaker',
    //     'tags': [
    //       'Lorem',
    //       'nisi',
    //       'ad'
    //     ],
    //     'price': {
    //       'sale': 25,
    //       'previous': 43
    //     },
    //     'ratings': {
    //       'rating': 3.72,
    //       'ratingCount': 18
    //     },
    //     'features': [
    //       'magna est',
    //       'consectetur dolor',
    //       'est proident'
    //     ],
    //     'photo': './assets/images/products/speaker-2.jpg',
    //     'gallery': [
    //       './assets/images/products/speaker-1.jpg',
    //       './assets/images/products/speaker-2.jpg'
    //     ],
    //     'badge': {
    //       'text': 'Sale',
    //       'color': 'primary'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210d9a8d6dda7256417',
    //     'name': 'Lightweight On-Ear Headphones - Black',
    //     'subtitle': 'On-ear fit to minimize noise so you can hear every beat',
    //     'description': 'sit laborum',
    //     'category': 'headphone',
    //     'tags': [
    //       'eu',
    //       'irure',
    //       'proident'
    //     ],
    //     'price': {
    //       'sale': 29,
    //       'previous': 55
    //     },
    //     'ratings': {
    //       'rating': 3.79,
    //       'ratingCount': 77
    //     },
    //     'features': [
    //       'laboris id',
    //       'magna eu',
    //       'sint quis'
    //     ],
    //     'photo': './assets/images/products/headphone-2.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '-40%',
    //       'color': 'info'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210e8329237332e56d7',
    //     'name': 'Automatic-self-wind mens Watch 5102PR-001 (Certified Pre-owned)',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'eiusmod elit',
    //     'category': 'watch',
    //     'tags': [
    //       'laborum',
    //       'minim',
    //       'tempor'
    //     ],
    //     'price': {
    //       'sale': 33,
    //       'previous': 58
    //     },
    //     'ratings': {
    //       'rating': 4.74,
    //       'ratingCount': 64
    //     },
    //     'features': [
    //       'cillum ullamco',
    //       'ad minim',
    //       'duis exercitation'
    //     ],
    //     'photo': './assets/images/products/watch-1.jpg',
    //     'gallery': [
    //       './assets/images/products/watch-1.jpg',
    //       './assets/images/products/watch-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '10% off',
    //       'color': 'info'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210cb9937d28c6eca1a',
    //     'name': 'Automatic-self-wind mens Watch 5102PR-001',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'dolore tempor',
    //     'category': 'watch',
    //     'tags': [
    //       'Lorem',
    //       'dolor',
    //       'duis'
    //     ],
    //     'price': {
    //       'sale': 38,
    //       'previous': 50
    //     },
    //     'ratings': {
    //       'rating': 4.43,
    //       'ratingCount': 98
    //     },
    //     'features': [
    //       'aliquip consequat',
    //       'excepteur non',
    //       'aliquip eu'
    //     ],
    //     'photo': './assets/images/products/watch-2.jpg',
    //     'gallery': [
    //       './assets/images/products/watch-1.jpg',
    //       './assets/images/products/watch-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '4% off',
    //       'color': 'info'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2106f155194e5c95d67',
    //     'name': 'On-Ear Headphones - Black',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'elit Lorem',
    //     'category': 'headphone',
    //     'tags': [
    //       'magna',
    //       'veniam',
    //       'sunt'
    //     ],
    //     'price': {
    //       'sale': 38,
    //       'previous': 54
    //     },
    //     'ratings': {
    //       'rating': 4.84,
    //       'ratingCount': 52
    //     },
    //     'features': [
    //       'est mollit',
    //       'adipisicing exercitation',
    //       'esse incididunt'
    //     ],
    //     'photo': './assets/images/products/headphone-3.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '$4 off',
    //       'color': 'success'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2101625a02fee92e27f',
    //     'name': 'In-Ear Headphone',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'proident non',
    //     'category': 'headphone',
    //     'tags': [
    //       'Lorem',
    //       'occaecat',
    //       'laborum'
    //     ],
    //     'price': {
    //       'sale': 31,
    //       'previous': 58
    //     },
    //     'ratings': {
    //       'rating': 3.18,
    //       'ratingCount': 90
    //     },
    //     'features': [
    //       'ullamco quis',
    //       'veniam laboris',
    //       'nulla sunt'
    //     ],
    //     'photo': './assets/images/products/headphone-4.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '$5 off',
    //       'color': 'primary'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2108970b01447ec34aa',
    //     'name': 'Duis exercitation nostrud anim',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'dolore enim',
    //     'category': 'phone',
    //     'tags': [
    //       'do',
    //       'aliqua',
    //       'irure'
    //     ],
    //     'price': {
    //       'sale': 22,
    //       'previous': 44
    //     },
    //     'ratings': {
    //       'rating': 3.53,
    //       'ratingCount': 47
    //     },
    //     'features': [
    //       'sunt laboris',
    //       'incididunt nulla',
    //       'ullamco qui'
    //     ],
    //     'photo': './assets/images/products/iphone-2.jpg',
    //     'gallery': [
    //       './assets/images/products/iphone-1.jpg',
    //       './assets/images/products/iphone-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2103c04707145e21300',
    //     'name': 'Dolor eu nostrud excepteur',
    //     'description': 'enim fugiat',
    //     'category': 'phone',
    //     'tags': [
    //       'laborum',
    //       'nulla',
    //       'sit'
    //     ],
    //     'price': {
    //       'sale': 31,
    //       'previous': 40
    //     },
    //     'ratings': {
    //       'rating': 3.42,
    //       'ratingCount': 35
    //     },
    //     'features': [
    //       'exercitation excepteur',
    //       'eiusmod mollit',
    //       'irure adipisicing'
    //     ],
    //     'photo': './assets/images/products/iphone-1.jpg',
    //     'gallery': [
    //       './assets/images/products/iphone-1.jpg',
    //       './assets/images/products/iphone-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae21021b2911c97ad6c5b',
    //     'name': 'Over-Ear Headphones, Stereo Lightweight Adjustable Wired Headset',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'sit commodo',
    //     'category': 'headphone',
    //     'tags': [
    //       'adipisicing',
    //       'labore',
    //       'voluptate'
    //     ],
    //     'price': {
    //       'sale': 33,
    //       'previous': 57
    //     },
    //     'ratings': {
    //       'rating': 3.51,
    //       'ratingCount': 60
    //     },
    //     'features': [
    //       'culpa id',
    //       'eu excepteur',
    //       'incididunt aute'
    //     ],
    //     'photo': './assets/images/products/headphone-1.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   }, {
    //     '_id': '5a9ae2106518248b68251fdf',
    //     'name': 'Wireless Bluetooth V4.0 Portable Speaker with HD Sound and Bass',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'Lorem ipsum dolor sit amet, et nec putent quodsi, admodum assentior ad duo. Pri ad sapientem ocurreret incorrupte',
    //     'category': 'speaker',
    //     'tags': [
    //       'sunt',
    //       'sunt',
    //       'culpa'
    //     ],
    //     'price': {
    //       'sale': 32,
    //       'previous': 54
    //     },
    //     'ratings': {
    //       'rating': 3.86,
    //       'ratingCount': 26
    //     },
    //     'features': [
    //       'aliquip aliquip',
    //       'nulla laboris',
    //       'pariatur consequat'
    //     ],
    //     'photo': './assets/images/products/speaker-1.jpg',
    //     'gallery': [
    //       './assets/images/products/speaker-1.jpg',
    //       './assets/images/products/speaker-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '20% off',
    //       'color': 'info'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210b7b4d3ad2f048dsbbe',
    //     'name': 'Portable Speaker with HD Sound',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'cillum eiusmod',
    //     'category': 'speaker',
    //     'tags': [
    //       'Lorem',
    //       'nisi',
    //       'ad'
    //     ],
    //     'price': {
    //       'sale': 25,
    //       'previous': 43
    //     },
    //     'ratings': {
    //       'rating': 3.72,
    //       'ratingCount': 18
    //     },
    //     'features': [
    //       'magna est',
    //       'consectetur dolor',
    //       'est proident'
    //     ],
    //     'photo': './assets/images/products/speaker-2.jpg',
    //     'gallery': [
    //       './assets/images/products/speaker-1.jpg',
    //       './assets/images/products/speaker-2.jpg'
    //     ],
    //     'badge': {
    //       'text': 'Sale',
    //       'color': 'primary'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2sd10d9a8d6dda7256417',
    //     'name': 'Lightweight On-Ear Headphones - Black',
    //     'subtitle': 'On-ear fit to minimize noise so you can hear every beat',
    //     'description': 'sit laborum',
    //     'category': 'headphone',
    //     'tags': [
    //       'eu',
    //       'irure',
    //       'proident'
    //     ],
    //     'price': {
    //       'sale': 29,
    //       'previous': 55
    //     },
    //     'ratings': {
    //       'rating': 3.79,
    //       'ratingCount': 77
    //     },
    //     'features': [
    //       'laboris id',
    //       'magna eu',
    //       'sint quis'
    //     ],
    //     'photo': './assets/images/products/headphone-2.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '-40%',
    //       'color': 'warning'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210e8329fs237332e56d7',
    //     'name': 'Automatic-self-wind mens Watch 5102PR-001 (Certified Pre-owned)',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'eiusmod elit',
    //     'category': 'watch',
    //     'tags': [
    //       'laborum',
    //       'minim',
    //       'tempor'
    //     ],
    //     'price': {
    //       'sale': 33,
    //       'previous': 58
    //     },
    //     'ratings': {
    //       'rating': 4.74,
    //       'ratingCount': 64
    //     },
    //     'features': [
    //       'cillum ullamco',
    //       'ad minim',
    //       'duis exercitation'
    //     ],
    //     'photo': './assets/images/products/watch-1.jpg',
    //     'gallery': [
    //       './assets/images/products/watch-1.jpg',
    //       './assets/images/products/watch-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae210cba9937d28c6eca1a',
    //     'name': 'Automatic-self-wind mens Watch 5102PR-001',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'dolore tempor',
    //     'category': 'watch',
    //     'tags': [
    //       'Lorem',
    //       'dolor',
    //       'duis'
    //     ],
    //     'price': {
    //       'sale': 38,
    //       'previous': 50
    //     },
    //     'ratings': {
    //       'rating': 4.43,
    //       'ratingCount': 98
    //     },
    //     'features': [
    //       'aliquip consequat',
    //       'excepteur non',
    //       'aliquip eu'
    //     ],
    //     'photo': './assets/images/products/watch-2.jpg',
    //     'gallery': [
    //       './assets/images/products/watch-1.jpg',
    //       './assets/images/products/watch-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5ad9ae2106f155194e5c95d67',
    //     'name': 'On-Ear Headphones - Black',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'elit Lorem',
    //     'category': 'headphone',
    //     'tags': [
    //       'magna',
    //       'veniam',
    //       'sunt'
    //     ],
    //     'price': {
    //       'sale': 38,
    //       'previous': 54
    //     },
    //     'ratings': {
    //       'rating': 4.84,
    //       'ratingCount': 52
    //     },
    //     'features': [
    //       'est mollit',
    //       'adipisicing exercitation',
    //       'esse incididunt'
    //     ],
    //     'photo': './assets/images/products/headphone-3.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2101625a02fee92fe27f',
    //     'name': 'In-Ear Headphone',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'proident non',
    //     'category': 'headphone',
    //     'tags': [
    //       'Lorem',
    //       'occaecat',
    //       'laborum'
    //     ],
    //     'price': {
    //       'sale': 31,
    //       'previous': 58
    //     },
    //     'ratings': {
    //       'rating': 3.18,
    //       'ratingCount': 90
    //     },
    //     'features': [
    //       'ullamco quis',
    //       'veniam laboris',
    //       'nulla sunt'
    //     ],
    //     'photo': './assets/images/products/headphone-4.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2108970bs01447ec34aa',
    //     'name': 'Duis exercitation nostrud anim',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'dolore enim',
    //     'category': 'phone',
    //     'tags': [
    //       'do',
    //       'aliqua',
    //       'irure'
    //     ],
    //     'price': {
    //       'sale': 22,
    //       'previous': 44
    //     },
    //     'ratings': {
    //       'rating': 3.53,
    //       'ratingCount': 47
    //     },
    //     'features': [
    //       'sunt laboris',
    //       'incididunt nulla',
    //       'ullamco qui'
    //     ],
    //     'photo': './assets/images/products/iphone-2.jpg',
    //     'gallery': [
    //       './assets/images/products/iphone-1.jpg',
    //       './assets/images/products/iphone-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9ae2103c0470f7145e21300',
    //     'name': 'Dolor eu nostrud excepteur',
    //     'description': 'enim fugiat',
    //     'category': 'phone',
    //     'tags': [
    //       'laborum',
    //       'nulla',
    //       'sit'
    //     ],
    //     'price': {
    //       'sale': 31,
    //       'previous': 40
    //     },
    //     'ratings': {
    //       'rating': 3.42,
    //       'ratingCount': 35
    //     },
    //     'features': [
    //       'exercitation excepteur',
    //       'eiusmod mollit',
    //       'irure adipisicing'
    //     ],
    //     'photo': './assets/images/products/iphone-1.jpg',
    //     'gallery': [
    //       './assets/images/products/iphone-1.jpg',
    //       './assets/images/products/iphone-2.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   },
    //   {
    //     '_id': '5a9aef21021b2911c97ad6c5b',
    //     'name': 'Over-Ear Headphones, Stereo Lightweight Adjustable Wired Headset',
    //     'subtitle': 'Admodum assentior ad duo',
    //     'description': 'sit commodo',
    //     'category': 'headphone',
    //     'tags': [
    //       'adipisicing',
    //       'labore',
    //       'voluptate'
    //     ],
    //     'price': {
    //       'sale': 33,
    //       'previous': 57
    //     },
    //     'ratings': {
    //       'rating': 3.51,
    //       'ratingCount': 60
    //     },
    //     'features': [
    //       'culpa id',
    //       'eu excepteur',
    //       'incididunt aute'
    //     ],
    //     'photo': './assets/images/products/headphone-1.jpg',
    //     'gallery': [
    //       './assets/images/products/headphone-1.jpg',
    //       './assets/images/products/headphone-2.jpg',
    //       './assets/images/products/headphone-3.jpg',
    //       './assets/images/products/headphone-4.jpg'
    //     ],
    //     'badge': {
    //       'text': '',
    //       'color': 'red'
    //     }
    //   }

    // ];
  }
