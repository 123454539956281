<div class="breadcrumb">
    <h1>Security settings</h1>
    <ul>
        <li><a href="">Settings</a></li>
        <li>Security</li>
    </ul>
</div>

<div class="separator-breadcrumb border-top"></div>
<div class="row">
    <div class="col-md-6">
        <h4>Customize your security settings</h4>
        <p>Change your security configuration</p>
        <div class="card mb-5">
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Autentication Methods:</label>
                        <div class="col-sm-10">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Configure</option>
                              </select>                 
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Connection to Active Directories:</label>
                        <div class="col-sm-10">
                            <select dsabled class="form-select" aria-label="Default select example">
                                <option selected>Configure</option>
                                <!-- <option value="1">Spanish</option>
                                <option value="2">English</option>
                                <option value="3">Italian</option> -->
                              </select>    
                        </div>
                    </div>
                    <legend class="col-form-label col-sm-2 pt-0">Proxy settings</legend>
                            <div class="d-flex flex-column">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Host">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Port">
                                </div>
                                <button class="btn btn-primary pd-x-20">Test connection and save</button>
                            </div>
                </form>
            </div>
        </div>
    </div>
</div>