<div class="breadcrumb">
    <h1>General settings</h1>
    <ul>
        <li><a href="">Settings</a></li>
        <li>General</li>
    </ul>
</div>

<div class="separator-breadcrumb border-top"></div>
<div class="row">
    <div class="col-md-6">
        <h4>Customize your settings</h4>
        <p>Custom your own tenant</p>
        <div class="card mb-5">
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Language</label>
                        <div class="col-sm-10">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Select your language</option>
                                <option value="1">Spanish</option>
                                <option value="2">English</option>
                                <option value="3">Italian</option>
                              </select>                 
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Reports</label>
                        <div class="col-sm-10">
                            <select dsabled class="form-select" aria-label="Default select example">
                                <option selected>Configure</option>
                                <!-- <option value="1">Spanish</option>
                                <option value="2">English</option>
                                <option value="3">Italian</option> -->
                              </select>    
                        </div>
                    </div>
                    <legend class="col-form-label col-sm-2 pt-0">Logging</legend>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Export logs</label>
                        <div class="col-sm-10">
                            <select dsabled class="form-select" aria-label="Default select example">
                                <option selected>Export</option>
                                <option value="1">CSV</option>
                                <option value="2">EXCEL</option>
                                <option value="3">PDF</option>
                              </select>    
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Integrate with SIEM:</label>
                        <div class="col-sm-10">
                            <select dsabled class="form-select" aria-label="Default select example">
                                <option selected>Configure</option>
                                <!-- <option value="1">Spanish</option>
                                <option value="2">English</option>
                                <option value="3">Italian</option> -->
                              </select>    
                        </div>
                    </div>
                    <legend class="col-form-label col-sm-2 pt-0">Incident Management</legend>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">Integrate with ticketing system:</label>
                        <div class="col-sm-10">
                            <select dsabled class="form-select" aria-label="Default select example">
                                <option selected>Configure</option>
                                <!-- <option value="1">Spanish</option>
                                <option value="2">English</option>
                                <option value="3">Italian</option> -->
                              </select>    
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>